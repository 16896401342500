import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentDisplay from '../components/content-display/content-display';
import DefaultLayout from '../components/default-layout/default-layout';
import SEO from '../components/seo/seo';

const Event = ({ data }) => {
  const content = data.nodeEvent;

  const image = {
    src:
      content.relationships.field_image &&
      content.relationships.field_image.max_1600_10_7
        ? content.relationships.field_image.max_1600_10_7.childImageSharp
          .resolutions.src
        : null,
    alt: content.field_image
      ? content.field_image.alt
      : null
  };

  // Reformat the metatag data from Drupal in a way we can use.
  const metatags = content.metatag.reduce((accumulator, currentVal) => {
    return {
      ...accumulator,
      [currentVal.attributes.name]: currentVal.attributes.content
    };
  }, {});

  return (
    <DefaultLayout data={data}>
      <SEO
        {...metatags}
        siteConfig={data.site.siteMetadata}
        pageUrl={content.path.alias}
        image={image}
      />
      <ContentDisplay {...content}
        spektrixClientName={data.site.siteMetadata.spektrixClientName}
      />
    </DefaultLayout>
  );
};

Event.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query EventsQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        spektrixClientName
      }
    }
    nodeEvent(path: { alias: { eq: $slug } }) {
      title
      drupal_id
      path {
        alias
      }
      longTitle: field_long_title
      date: field_date {
        full: value(formatString: "MMMM Do, h:mma")
        medium: value(formatString: "MMMM D, Y")
        year: value(formatString: "Y")
        time: value(formatString: "h:mma")
        raw: value
        format: format
      }
      created
      tickets: field_tickets {
        uri,
        title
      }
      subscription: field_subscription {
        uri,
        title
      }
      cost: field_cost
      field_webpage {
        uri
        title
      }
      body {
        processed
        summary
      }
      html: field_html {
        value
      }
      metatag: metatag {
        attributes {
          name
          content
        }
      }
      field_image {
        alt
      }
      feedMeta: feeds_item {
        guid
      }
      fields {
        spectrix {
          cancelled
          id
          numberInstances
        }
      }
      relationships {
      venue: field_venue {
        ... on VenueUnion {
            ... on node__dining {
              title
              drupal_id
            }
            ... on node__place {
              title
              drupal_id
              path {
                alias
              }
            }
          }
      }
        series: field_series {
          name
        }
        field_image {
          max_1600_10_7 {
            childImageSharp {
              # https://www.gatsbyjs.org/packages/gatsby-image/?=#how-to-use
              resolutions(width: 470, height: 330, quality: 90) {
                ...GatsbyImageSharpResolutions
              }
            }
          }
          max_1600_16_9 {
            childImageSharp {
              # https://www.gatsbyjs.org/packages/gatsby-image/?=#how-to-use
              sizes(maxWidth: 992, maxHeight: 558) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`;

export default Event;
